import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import Cookies from "js-cookie";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/berita",
        name: "berita",
        component: () => import("@/views/berita/Index.vue"),
      },
      {
        path: "/search",
        name: "search",
        component: () => import("@/views/Search.vue"),
      },
      {
        path: "/berita/add",
        name: "berita-add",
        component: () => import("@/views/berita/Add.vue"),
      },
      {
        path: "/berita/formed-add-more",
        name: "berita-formed-add-more",
        component: () => import("@/views/berita/FormedAddMultipleForm.vue"),
      },
      {
        path: "/berita/socmed-add",
        name: "berita-socmed-add",
        component: () => import("@/views/berita/SocmedAddForm.vue"),
      },
      {
        path: "/berita/detail/:id",
        name: "berita-detail",
        component: () => import("@/views/berita/Detail.vue"),
      },
      {
        path: "/berita/edit/:id",
        name: "berita-edit",
        component: () => import("@/views/berita/Edit.vue"),
      },
      {
        path: "/berita/edit-medsos/:id",
        name: "berita-edit-medsos",
        component: () => import("@/views/berita/EditMedsos.vue"),
      },
      {
        path: "/users",
        name: "users",
        component: () => import("@/views/master/pengguna/Index.vue"),
      },
      {
        path: "/medias",
        name: "medias",
        component: () => import("@/views/master/media/Index.vue"),
      },
      {
        path: "/sequence-labeling",
        name: "sequence-labeling",
        component: () => import("@/views/master/labeling/Index.vue"),
      },
      {
        path: "/medias/add",
        name: "medias-add",
        component: () => import("@/views/master/media/Add.vue"),
      },
      {
        path: "/users/add",
        name: "users-add",
        component: () => import("@/views/master/pengguna/Add.vue"),
      },
      {
        path: "/log/user",
        name: "logs-user",
        component: () => import("@/views/master/logs/logUser/Index.vue"),
      },
      {
        path: "/log/crawler",
        name: "logs-crawler",
        component: () => import("@/views/master/logs/logCrawler/index.vue"),
      },
      {
        path: "/log/crawler-medmon/:name",
        name: "logs-crawler-medmon",
        component: () =>
          import("@/views/master/logs/logCrawler/detailCrawler.vue"),
      },
      {
        path: "/log/crawler/:name",
        name: "logs-crawler-detail",
        component: () => import("@/views/master/logs/logCrawler/detail.vue"),
      },
      {
        path: "/profile",
        name: "profile-user",
        component: () => import("@/views/profile/index.vue"),
      },
      {
        path: "/highlight-pdf",
        name: "highlight-pdf",
        component: () => import("@/views/highlight-pdf/index.vue"),
      },
      {
        path: "/detail-highlight-pdf",
        name: "detail-highlight-pdf",
        component: () => import("@/views/highlight-pdf/detail.vue"),
      },
      {
        path: "/OcrImage",
        name: "OcrImage",
        component: () => import("@/views/berita/OcrImage.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);

  if (to.name != "sign-in") {
    store
      .dispatch(Actions.VERIFY_AUTH)
      .then(() => {
        next();
      })
      .catch((err) => {
        Cookies.set("last_page", to.fullPath);
        next({ name: "sign-in" });
      });
  } else {
    next();
  }
});

export default router;
