import Cookies from 'js-cookie'

const ID_TOKEN_KEY = "accessToken" as string;
const ID_REFRESHER_KEY = "refreshToken" as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  // return window.localStorage.getItem(ID_TOKEN_KEY) == 'undefined' ? null : window.localStorage.getItem(ID_TOKEN_KEY);
  return Cookies.get(ID_TOKEN_KEY);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  // window.localStorage.setItem(ID_TOKEN_KEY, token);
  Cookies.set(ID_TOKEN_KEY, token);
};

/**
 * @description save refresh token into localStorage
 * @param token: string
 */
 export const saveRefreshToken = (token: string): void => {
  // window.localStorage.setItem(ID_REFRESHER_KEY, token);
  Cookies.set(ID_REFRESHER_KEY, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  // window.localStorage.removeItem(ID_TOKEN_KEY);
  // window.localStorage.removeItem(ID_REFRESHER_KEY);
  Cookies.remove(ID_TOKEN_KEY);
  Cookies.remove(ID_REFRESHER_KEY);
};

export default { getToken, saveToken, saveRefreshToken, destroyToken };
